.About {
  margin-top: 6rem;
}

.Abouth3 {
  color: #0055fe;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem auto;
  text-align: center;
}

.AboutParagraphs {
  font-family: inherit;
  color: white;
  padding: 0 1rem;
  line-height: 1.75rem;
}

.AboutParagraphs p {
  padding: 0.5rem 0;
}

@media (min-width: 40rem) {
  .About {
    margin-top: 3rem;
  }
  

  .AboutParagraphs {
    padding-left: 1.3rem;
  }
}

.Toolbar {
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  background: #151515;
  padding: 0.5rem 1rem;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0px 0px 10px #0055fe;
}

@media (min-width: 40rem) {
  .Toolbar {
    justify-content: space-between;
  }
}

@media (max-width: 639px) {
  .DesktopOnly {
    display: none;
  }
}

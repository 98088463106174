.Section {
  width: 90%;
  max-width: 50rem;
  margin: 0 auto;
  min-height: calc(100vh - 3rem - 9rem);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

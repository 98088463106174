.ProjectItem {
  width: 250px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;
}

.ProjectImageHolder {
  width: 250px;
  height: 215px;
  box-shadow: 4px 4px 8px #1b1b1b;
  background-color: white;
}

.TechList {
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.TechListItem {
  padding: 0 0.5rem;
  text-align: left;
  color: white;
}

.clickIcons {
  margin: 0;
  padding: 0;
  margin-top: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickIcons li {
  width: 40px;
  height: 40px;
  list-style: none;
  fill: white;
  cursor: pointer;
  padding: 0 0.5rem;
}

.clickIcons li div {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (min-width: 40rem) {
  .ProjectItem {
    margin-right: 2rem;
  }
}

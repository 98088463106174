.SideDraw {
  position: fixed;
  top: 0;
  left: 0;
  width: 480px;
  max-width: 70%;
  height: 100vh;
  background-color: white;
  z-index: 300;

  transition: transform 0.3s ease-out;
}

.MobileNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

@media (min-width: 40rem) {
  .MobileNav {
    display: none;
  }
}

.Portfolio {
  margin-top: 6rem;
}

.portSec__headerThree {
  color: #0055fe;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}

.Port_Project_Container {
  margin-top: 3rem;

  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
}

@media (min-width: 40rem) {
  .Portfolio {
    margin-top: 9rem;
  }
  .Port_Project_Container {
    align-items: flex-start;
    justify-content: space-evenly;
  }
}

.NavItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoHolder {
  display: flex;
  justify-content: center;
}

@media (min-width: 40rem) {
  .NavItems {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
